<script>
import { h, watch, reactive, Transition } from 'vue'
import useEmitter from '@/composables/useEmitter'

export default {
  props: {
    src: String
  },

  emits: ['loading', 'play', 'ended'],

  setup (props, { emit }) {
    const emitter = useEmitter()
    const state   = reactive({
      key: null,
      show: false,
      src: null,
      playing: false
    })

    watch(() => props.src, newSrc => setSrc(newSrc))

    const transitionOptions = {
      name: 'fade',
      mode: 'in-out',
      appear: true
    }

    const setSrc = (src) => {
      // don't do anything if an video is already playing
      if (state.playing) return
      // set src (string) as key
      state.key = src
      // trigger events
      emit('loading')
      emitter.emit('loading-start')
      // preload new video as blob
      fetch(src)
        .then(res  => res.blob())
        .then(blob => {
          state.src  = URL.createObjectURL(blob)
          state.show = true
          emitter.emit('loading-stop')
        })
    }

    const handleOnPlay = () => {
      emit('play')
      state.playing = true
    }

    const handleOnEnded = () => {
      emit('ended')
      state.playing = false
    }

    return () => h(Transition, transitionOptions, () => state.show
      ? h('video', {
          key: state.key,
          src: state.src,
          muted: true,
          autoplay: true,
          playsinline: true,
          onPlay:  handleOnPlay,
          onEnded: handleOnEnded
        })
      : null
    )

  } // end: setup
}   // end: export
</script>


<style lang="scss" scoped>
video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation-duration: 250ms;
}
</style>
