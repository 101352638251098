const cookies = []
cookies['matomo-accept']  = new Event('cookie-matomo-accept')
cookies['matomo-decline'] = new Event('cookie-matomo-decline')

const config = {

  testing: false,
  elementID: 'klaro',

  styling: {
    theme: ['bottom'],
  },

  storageMethod: 'cookie',
  storageName: 'klaro',
  htmlTexts: true,
  embedded: false,
  noAutoLoad: false,
  groupByPurpose: true,
  lang: 'de',

  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: true,
  hideLearnMore: false,
  disablePoweredBy: true,
  noticeAsModal: false,

  translations: {
    zz: {
      privacyPolicyUrl: 'https://www.e-mobilbw.de/datenschutz',
    },
    de: {
      acceptOnce: 'Akzeptieren',
      ok: 'Akzeptieren',
      contextualConsent: {
        acceptOnce: 'Akzeptieren',
      },

      privacyPolicy: {
        name: "Datenschutzerklärung",
        text: `
          Um unsere Webseite für Sie optimal zu gestalten und unsere
          Inhalte für Sie weiter zu verbessern, verwenden wir Cookies.
          Das Einverständnis in die Verwendung der Cookies können Sie
          jederzeit widerrufen. Weitere Informationen finden Sie in unserer
          <a href="https://www.e-mobilbw.de/datenschutz" target="_blank">
            Datenschutzerklärung
          </a>
          und im
          <a href="https://www.e-mobilbw.de/impressum" target="_blank">
            Impressum
          </a>.`
      },

      consentNotice: {
        description: `
          Um unsere Webseite für Sie optimal zu gestalten und unsere
          Inhalte für Sie weiter zu verbessern, verwenden wir Cookies.
          Das Einverständnis in die Verwendung der Cookies können Sie
          jederzeit widerrufen. Weitere Informationen finden Sie in unserer
          <a href="https://www.e-mobilbw.de/datenschutz" target="_blank">
            Datenschutzerklärung
          </a>
          und im
          <a href="https://www.e-mobilbw.de/impressum" target="_blank">
            Impressum
          </a>.`,
        learnMore: 'Cookies verwalten'
      },

      consentModal: {
        title: 'Hinweis zu Cookies',
        description: ' ',
        privacyPolicy: {
          name: "xxx",
          text: "yyy"
        },
      },

      matomo: {
        description: 'Sammeln von Besucherstatistiken',
      },

      purposes: {
        analytics: {
          title: 'Besucher-Statistiken',
          description: 'Diese Dienste verarbeiten persönliche Daten, um eine Verhaltensstatistik zu ermitteln damit wir unsere Webseite noch besser für Sie optimieren können.',
        },
        services: {
          title: 'Services',
          description: 'Diese Dienste laden Daten aus externen Quellen und übermitteln ggf. persönliche Daten.'
        },
        security: {
          title: 'Sicherheit'
        },
        livechat: {
          title: 'Live Chat'
        },
        advertising: {
          title: 'Anzeigen von Werbung'
        },
        styling: {
          title: 'Styling'
        },
      },
    },
  },

  /*
  Here you specify the third-party services that Klaro will manage for you.
  */
  services: [
    // {
    //     name: 'google-tag-manager',
    //     default: true,
    //     // Setting this to true will exempt this service from the "Accept All"
    //     // flow, i.e. clicking on "Accept All" will not enable this service.
    //     // contextualConsentOnly: true,
    //     required: true,
    //     purposes: ['functional'],
    //     onAccept: `
    //         // we notify the tag manager about all services that were accepted. You can define
    //         // a custom event in GTM to load the service if consent was given.
    //         for(let k of Object.keys(opts.consents)){
    //             if (opts.consents[k]){
    //                 let eventName = 'klaro-'+k+'-accepted'
    //                 dataLayer.push({'event': eventName})
    //             }
    //         }
    //         // if consent for Google Analytics was granted we enable analytics storage
    //         if (opts.consents[opts.vars.googleAnalyticsName || 'google-analytics']){
    //             console.log("Google analytics usage was granted")
    //             gtag('consent', 'update', {'analytics_storage': 'granted'})
    //         }
    //         // if consent for Google Ads was granted we enable ad storage
    //         if (opts.consents[opts.vars.adStorageName || 'google-ads']){
    //             console.log("Google ads usage was granted")
    //             gtag('consent', 'update', {'ad_storage': 'granted'})
    //         }
    //     `,
    //     onInit: `
    //         // initialization code here (will be executed only once per page-load)
    //         window.dataLayer = window.dataLayer || [];
    //         window.gtag = function(){dataLayer.push(arguments)}
    //         gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'})
    //         gtag('set', 'ads_data_redaction', true)
    //     `,
    //     onDecline: `
    //         // initialization code here (will be executed only once per page-load)
    //         window.dataLayer = window.dataLayer || [];
    //         window.gtag = function(){dataLayer.push(arguments)}
    //         gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'})
    //         gtag('set', 'ads_data_redaction', true)
    //     `,
    //     vars: {
    //         googleAnalytics: 'google-analytics'
    //     }
    // },
    {
      name: 'matomo',
      default: false,
      translations: {
        zz: {
          title: 'Matomo/Piwik'
        },
        de: {
          description: 'Matomo ist ein einfacher, selbstgehosteter Analytics-Service.'
        }
      },
      purposes: ['analytics'],
      required: false,
      optOut: false,
      onlyOnce: false,
      onAccept:  () => dispatchEvent(cookies['matomo-accept']),
      onDecline: () => dispatchEvent(cookies['matomo-decline'])
    },
    {
      name: 'PHP Session',
      purposes: ['functional'],
      default: true,
      required: true,
    },
    /*
    {
        name: 'google-tag-manager',
          default: true,
          required: true,
          purposes: ['functional'],
    },
    */
  ],
};

module.exports = {
  config
}
