import { createStore } from 'vuex'

export default createStore({
  state: {
    pages: []
  },

  getters: {
    pages: (state) => state.pages,
    rootPages: (state) => state.pages.filter(p => p.parent === 0).sort((a, b) => a.order - b.order)
  },

  mutations: {
    SET_PAGES: (state, pages) => state.pages = pages
  },

  actions: {
    setPages: ({ commit }, pages) => commit('SET_PAGES', pages)
  }
})
