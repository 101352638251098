<template>
  <div class="home" ref="home">
    <BreadcrumbsNavigation :page="page" />
    <div
        class="video-container"
        ref="container">
      <VideoPlayer
          :src="state.src"
          @play="state.showTouchpoints = false"
          @ended="goTo()" />
      <div v-if="state.showTouchpoints">
        <TouchPoint
            v-for="(page, idx) in rootPages"
            :key="page.id"
            :class="[page.slug]"
            :title="page.title"
            @click="playAnimation(page, idx)" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useEmitter from '@/composables/useEmitter'
import BreadcrumbsNavigation from '@/components/BreadcrumbsNavigation'
import TouchPoint from '@/components/TouchPoint'
import VideoPlayer from '@/components/VideoPlayer'

export default {
  components: {
    BreadcrumbsNavigation,
    TouchPoint,
    VideoPlayer
  },

  setup() {
    const router    = useRouter()
    const store     = useStore()
    const emitter   = useEmitter()
    const home      = ref(null)
    const container = ref(null)
    const state     = ref({
      showTouchpoints: true
    })
    const page      = {
      id: 0,
      title: { rendered: '<svg viewBox="0 0 20 19"><path d="M8,17 L8,11 L12,11 L12,17 L17,17 L17,9 L20,9 L10,0 L0,9 L3,9 L3,17 L8,17 Z" /></svg> Home' }
    }
    const rootPages = computed(() => store.getters.rootPages)

    const setContainerSize = () => {
      if (!container.value) return
      let maxHeight = home.value.clientHeight

      let height = maxHeight
      let width  = height * 1.6

      if (width < home.value.clientWidth) {
        height = home.value.clientWidth / 1.6
        width  = height * 1.6
      }

      container.value.style.width  = `${width}px`
      container.value.style.height = `${height}px`
    }

    const playAnimation = (page, idx) => {
      emitter.emit('play-btn-sound')
      state.value.src  = `/static/nemo_${idx + 1}.mp4`
      state.value.page = page
    }

    const goTo = () => {
      router.push({
        name: 'Topic',
        params: {
          id: state.value.page.id,
          fromHome: true
        }
      })
    }

    onMounted(() => setContainerSize())

    window.onresize = () => setContainerSize()

    return {
      page,
      rootPages,
      home,
      container,
      playAnimation,
      goTo,
      state
    }
  }
}
</script>


<style lang="scss" scoped>
.home {
  position: absolute;
  top: 6%;
  left: 15%;
  width: 85%;
  height: 94%;
  overflow: hidden;
  padding: 2rem;

  .video-container {
    background: url('/static/img/home.jpg') no-repeat center center;
    background-size: cover;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  .touchpoint {
    &.umwelt {
      left: 23%;
      top: 40%;
    }

    &.mobilitaet {
      left:72%;
      top: 68%;
    }

    &.technik {
      left: 70%;
      top: 42%;
    }

    &.automatisierung {
      left: 37%;
      top: 68%;
    }

    &.gesellschaftliche-auswirkung {
      left: 53.75%;
      top: 25%;
    }
  }
}
</style>
