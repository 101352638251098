import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Page from '../views/PageView.vue'
import Meta from '../views/MetaView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/topic/:id',
    name: 'Topic',
    component: () => import(/* webpackChunkName: "Topic" */ '../views/TopicView.vue')
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: Page
  },
  {
    path: '/meta/:id',
    name: 'Meta',
    component: Meta
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404View.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
