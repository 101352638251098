<template>
  <router-view
      v-slot="{ Component }">
    <transition
        :enter-active-class="route.params.fromHome ? 'animated fadeIn'  : 'animated slideInDown'"
        :leave-active-class="route.params.fromHome ? 'animated fadeOut' : 'animated slideOutDown'">
      <component
          :is="Component"
          :key="$route.path" />
    </transition>
  </router-view>
</template>


<script>
import { useRoute } from 'vue-router'

export default {
  setup () {
    const route = useRoute()

    return {
      route
    }
  }
}
</script>


<style lang="scss" scoped>
.fadeIn {
  animation-duration: 350ms;
}

.fadeOut {
  animation-delay: 500ms;
}
</style>
