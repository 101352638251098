<template>
  <div
      class="page"
      v-if="state.page.id">
    <BreadcrumbsNavigation :page="state.page" />
    <header>
      <transition name="fade" appear>
        <img
            v-if="state.header.source_url"
            :src="state.header.source_url"
            :alt="state.header.title.rendered">
      </transition>
      <div
          v-if="state.page.parent !== 0"
          class="mask" />
      <div class="header-content">
        <div
            v-if="state.page.parent !== 0"
            class="back"
            @click="goTo(state.page, true)">
          ⇠ zurück
        </div>
        <h1>{{ state.page.title.rendered }}</h1>
        <ul v-if="childPages.length !== 0" class="child-pages">
          <li
              v-for="page in childPages"
              :key="page.id"
              @click="goTo(page)">
            {{ page.title }}
          </li>
        </ul>
      </div>
      <span
          v-if="state.page.content.rendered !== ''"
          class="scroll-icon">
        <span class="scroll-icon__dot" />
        <span class="scroll-desc">scrollen</span>
      </span>
    </header>
    <div
        class="ar-16-9"
        v-if="state.page.acf.infogram_id">
      <iframe :src="`https://e.infogram.com/${state.page.acf.infogram_id}?src=embed`" />
    </div>
    <div
        v-if="state.page.content.rendered !== ''"
        class="wp-content"
        v-html="state.page.content.rendered"
        @click="catchEvent" />
  </div>
</template>

<script>
/* eslint-disable */
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import useEmitter from '@/composables/useEmitter'
import axios from 'axios'
import { onBeforeMount, reactive } from 'vue'
import BreadcrumbsNavigation from '@/components/BreadcrumbsNavigation'

export default {
  components: {
    BreadcrumbsNavigation
  },

  setup() {
    const store  = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()
    const state = reactive({
      header: {},
      page: {}
    })

    const childPages = computed(() => store.getters.pages.filter((page) => page.parent === parseInt(route.params.id)))

    const loadPage = async (id) => {
      await axios.get(`${process.env.VUE_APP_BACKEND}/wp-json/wp/v2/pages/${id}`)
        .then(res => {
          const page = res.data
          if (page.type === 'page' && page.status === 'publish') {
            state.page = res.data
            const headerImgId = res.data.featured_media
            if (headerImgId) {
              axios.get(`${process.env.VUE_APP_BACKEND}/wp-json/wp/v2/media/${headerImgId}`).then(res => {
                state.header = res.data
              })
            }
          }
          else router.push({ name: '404' })
        })
        .catch(() => router.push({ name: '404' }))
    }

    const goTo = (page, back) => {
      emitter.emit('play-btn-sound')
      router.push({
        name: page.parent === 0 ? 'Topic' : 'Page',
        params: { id: back ? page.parent : page.id }
      })
    }

    const catchEvent = (e) => {
      let a = e.target
      if (a.localName !== 'a') {
        if (a.parentNode.localName === 'a') a = a.parentNode
        else return
      }
      e.preventDefault()
      const page = store.getters.pages.find((page) => page.link === a.href)
      goTo(page)
    }

    onBeforeMount(() => {
      loadPage(route.params.id)
    })

    return {
      state,
      route,
      catchEvent,
      childPages,
      goTo
    }
  }
}
</script>


<style lang="scss" scoped>
header {
  margin: -2rem -2rem 3rem -2rem;
  width: calc(100% + 4rem);
  height: 100%;
  background: #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .mask {
    position: absolute;
    background: linear-gradient(180deg, rgba($color: #4dbdbf, $alpha: 0.35), rgba($color: #1c1c24, $alpha: 0.65));
    width: 100%;
    height: 100%;
  }

  .header-content {
    position: sticky;
    top: -1.25rem;
    z-index: 100;
    text-align: center;
    margin: 0.75rem 2rem;
    padding-bottom: 2rem;
    border-radius: 0.5rem;
    max-width: 80%;
    background: rgba($color: #1c1c24, $alpha: 0.25) repeating-linear-gradient( -45deg, rgba($color: #1c1c24, $alpha: 0.35), rgba($color: #1c1c24, $alpha: 0.35) 2px, transparent 2px, transparent 6px );

    .back {
      background-color: #15161b;
      border-radius: 0.5rem;
      margin-bottom: 2rem;
      padding: 0.5rem 1rem;
      color: #4dbdbf;
      font-weight: 600;
      font-size: 0.8rem;
      transition: all 250ms ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #000;
        color: #fff;
      }
    }

    h1 {
      font-size: 4rem;
      line-height: 1.1em;
      font-weight: 400;
      margin: 2rem 2rem 0 2rem;
      color: #fff;
    }

    .child-pages {
      padding: 0;
      margin: 2rem 2rem 0 2rem;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin: 0.35rem;
        background-color: #4dbdbf;
        color: #000;
        padding: 0.25rem 0.85rem;
        border-radius: 1.5rem;
        cursor: pointer;
        transition: all 250ms ease-in-out;
        font-weight: 600;

        &:hover {
          background-color: #fff;
          color: #222;
        }
      }
    }
  }
}

.page {
  position: absolute;
  top: 6%;
  left: 15%;
  width: 85%;
  height: 94%;
  overflow: auto;
  padding: 2rem;
  background: #fff;
}

.ar-16-9 {
  position: relative;
  padding-top: 59%;
  border-radius: 0.5rem;
  margin-top: -2rem;
  margin-bottom: 2rem;

  iframe {
    margin: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    border: none;
  }
}
</style>
