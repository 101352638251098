<template>
  <div
      ref="root"
      class="root">
    <div class="portrait-note">
      Die App erfordert den Landscape-Modus.
    </div>
    <PageHeader />
    <div id="breadcrumbs" />
    <RouterView />
    <LoadingSpinner v-if="loading" />
    <div id="modals" />
  </div>
</template>


<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import useEmitter from '@/composables/useEmitter'
import { appHeight, toggleFullscreen } from '@/tools.js'
import RouterView from '@/views/RouterView.vue'
import PageHeader from '@/components/PageHeader'
import LoadingSpinner from '@/components/LoadingSpinner'

import * as klaro from 'klaro'
import { config as klaroConfig } from '@/klaro.js'

const btnSoundFile = require('@/assets/sounds/btn.mp3')
const btnSound = new Audio(btnSoundFile)
window.addEventListener('resize', appHeight)
appHeight()

export default {
  name: 'app',
  components: {
    RouterView,
    PageHeader,
    LoadingSpinner
  },

  setup: () => {
    const store   = useStore()
    const emitter = useEmitter()
    const root = ref(null)
    const loading = ref(false)

    const playBtnSound = () => {
      btnSound.volume = '0.1'
      /*
      const promise = btnSound.play()
      if (promise !== undefined) promise.catch(() => {})
      */
    }

    const fullscreen = () => {
      playBtnSound()
      toggleFullscreen(root.value)
    }

    const createPageLookup = async () => {
      emitter.emit('loading-start')

      const perPage    = 100
      const pageLookup = []

      let pages = []
      let hasMorePages = true

      for (let pageNumber = 1; hasMorePages; pageNumber++) {

        const params = new URLSearchParams()
        params.append('per_page', perPage)
        params.append('page', pageNumber)

        const url = `${process.env.VUE_APP_BACKEND}/wp-json/wp/v2/pages?${params}`
        const result = await axios.get(url)
        pages.push(...result.data)

        const pagesInThisSet = result.data.length
        const totalPages     = result.headers['x-wp-totalpages']
        // Log
        console.log(`Loaded set ${pageNumber}/${totalPages} which contained ${pagesInThisSet} pages.`)

        hasMorePages = pagesInThisSet > 0 && pageNumber < totalPages

        if (!hasMorePages) {
          console.log('Filtering pages.')
          pages
            .filter(page => page.status === 'publish' && page.menu_order !== 99) // order 99: meta pages
            .sort((a, b) => a.menu_order - b.menu_order)
            .map((page) => {
              pageLookup.push({
                id: page.id,
                parent: page.parent,
                slug: page.slug,
                title: page.title.rendered,
                link: page.link,
                order: page.menu_order
              })
            })
          console.log('Done.')
          store.dispatch('setPages', pageLookup)
          emitter.emit('loading-stop')
        }
      }
    }

    addEventListener('cookie-matomo-accept', () => {
      window._paq.push(['setConsentGiven'])
      window._paq.push(['setCookieConsentGiven'])
      //window._paq.push(['rememberConsentGiven'])
      //window._paq.push(['rememberCookieConsentGiven'])
    })

    addEventListener('cookie-matomo-decline', () => {
      window._paq.push(['forgetConsentGiven'])
      window._paq.push(['forgetCookieConsentGiven'])
    })

    onMounted(() => {
      emitter.on('toggle-fullscreen', () => fullscreen())
      emitter.on('play-btn-sound',    () => playBtnSound())
      emitter.on('loading-start',     () => loading.value = true)
      emitter.on('loading-stop',      () => loading.value = false)
      createPageLookup()
      klaro.setup(klaroConfig)
    })

    return {
      root,
      fullscreen,
      loading
    }
  }
}
</script>


<style lang="scss">
@import '~@/assets/scss/colors';
@import '~@/assets/scss/fonts';

$animationDuration: 1s; /* Default value: 1s */
@import 'vue2-animate/src/sass/vue2-animate';

:root {
  --app-height: 100%;
  --mainColor: #5b6e7f;
  --mainFontColor: #fff;

  /* klaro */
  --green1: #4dbdbf;
  --green2: #4dbdbf;
  --button-text-color: #000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.klaro {
  line-height: 1.35em;

  .slider {
    box-shadow: none !important;
  }

  .cookie-notice {
    background: #1c1c24 !important;

    .cn-body {
      padding: 1rem 1.5rem !important;
    }
  }

  .cookie-modal {
    .cm-modal {
      background: #1c1c24 !important;

      .cm-header {
        padding: 2rem;

        .title {
          margin-bottom: 1rem;
        }
      }

      .cm-body {
        padding: 2rem;

        ul.cm-purposes {
          li.cm-purpose {
            line-height: 1.2em;
            padding-left: 80px;

            .cm-services {
              margin: 0.5rem 0;

              .cm-content {
                margin-left: -60px;

                .cm-service {
                  line-height: 1.2em;
                }
              }
            }
          }
        }
      }

      .cm-footer {
        padding: 2rem;
      }
    }
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: calc(3px + 0.7vw + 0.7vh);
  line-height: 140%;
  font-family: 'Roboto Condensed', 'Neue Helvetica', Helvetica, Arial, sans-serif;
  touch-action: none;
  color: #222f3e;
  background: #222;
  //user-select: none;
}

*:active,
*:focus {
  outline: none;
  box-shadow: none;
}

.root {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;

  @media not all and (hover:hover) {
    height: var(--app-height);
  }
}

.portrait-note {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: var(--mainColor);
  color: var(--mainFontColor);
  font-weight: 900;
  padding: 10%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  line-height: 120%;
  display: none;

  @media screen and (orientation: portrait) {
    display: flex;
  }
}

#breadcrumbs {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 6%;
  padding: 0rem;
  display: flex;
  align-items: center;
  z-index: 100;
  font-size: 1rem;
  background: #101114;
  border-bottom: 2px solid #000;
}

#modals {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}

// General icon style
.scroll-icon {
  display: block;
  position: absolute;
  left: calc(50% - 0.875rem);
  bottom: 2.25rem;
  height: 3rem;
  width: 1.75rem;
  border: .2em solid #fff;
  border-radius: 1em;
  z-index: 10;

  .scroll-icon__dot {
    display: block;
    position: absolute;
    left: 50%;
    background: #fff;
    height: .5rem;
    width: .5rem;
    top: .5rem;
    margin-left: -.25rem;
    border-radius: 50%;
    transform-origin: top center;
    backface-visibility: hidden;
    animation: scroll_2 2s ease-out infinite;
  }

  .scroll-desc {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 125%);
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
}

@keyframes scroll_2 {
  0%   { transform: translateY(0); opacity: 1; }
  25%  { opacity: 1; }
  75%  { transform: translateY(.75em); opacity: 0; }
  100% { transform: translateY(0); opacity: 0; }
}

.wp-content {
  background-color: #ffffff;
  opacity: 1;
  padding: 0.1rem 0;
  //background-size: 10% 10%;
  //background-image:  repeating-linear-gradient(to right, rgba($color: #4dbdbf, $alpha: 0.15), rgba($color: #4dbdbf, $alpha: 0.15) 1px, #ffffff 1px, #ffffff);

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    color: #4dbdbf;
    margin: 2.5rem 0 1.5rem 0;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin: 1.5rem 0;

    &:first-child {
      margin-top: 0;
    }
  }

  sup {
    line-height: 0;
    font-size: 0.75em;
    vertical-align: super;
  }

  sub {
    line-height: 0;
    font-size: 0.75em;
    vertical-align: sub;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    border-radius: 0.5rem;
  }

  figcaption {
    background: #1c1c24;
    color: #fff;
    font-size: 0.85rem;
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 1rem;
      height: 1rem;
      top: 0;
      left: 1.5rem;
      background: #1c1c24;
      transform: rotate(45deg) translateY(-70%);
    }
  }

  ul {
    padding: 0 0 0 0.9rem;
    margin: 1.5rem 0;

    &:first-child {
      margin-top: 0;
    }

    li {
      padding-left: 0.5rem;
      margin-bottom: 0.75rem;
    }
  }

  a {
    color: #4dbdbf;
    text-decoration: none;
    transition: all 250ms ease-in-out;

    img {
      transition: all 250ms ease-in-out;
    }

    &:hover {
      color: #2d7475;

      img {
        opacity: 0.5;
      }
    }
  }

  .wp-block-table {
    &.is-style-regular {

    }

    &.is-style-stripes {
      table {
        //border-collapse: separate;
      }

      tr:nth-child(even) {
        background-color: #eef4f8;
      }

      thead {
        th {
          border-bottom: 2px solid rgba($color: #002, $alpha: 1);
        }
      }

      th,
      td {
        padding: 0.75rem;
        border: none;
      }
    }

    table {
      width: 100%;
      padding: 0;
      margin: 0;
      border-collapse: collapse;

      &.has-fixed-layout {
        table-layout: fixed;
      }

      th,
      td {
        padding: 0.75rem;
        border: 1px solid #dbe2e6;

        &.has-text-align-left {
          text-align: left;
        }

        &.has-text-align-center {
          text-align: center;
        }

        &.has-text-align-right {
          text-align: right;
        }
      }

      thead {
        th {
          text-align: left;
          border-bottom: 2px solid rgba($color: #002, $alpha: 1);
        }
      }
    }
  }

  .wp-block-media-text {
    display: grid;
    grid-template-columns:
      minmax(20%, 30%)
      minmax(70%, 80%);
    gap: 0 10%;
    width: 100%;
    margin: 3rem 0;

    figure {
      border-radius: 1rem;
      //border: 1px solid rgba($color: #4dbdbf, $alpha: 0.5);
      height: fit-content;
      overflow: hidden;
    }
  }

  .wp-block-columns {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    gap: 0 5%;
    width: 100%;
    margin: 1.5rem 0;

    .wp-block-column {
      background: transparent;
    }
  }
}
</style>
