<template>
  <div class="page" v-if="state.page.id">
    <BreadcrumbsNavigation :page="state.page" />
    <h1>{{ state.page.title.rendered }}</h1>
    <div
        v-if="state.page.content.rendered !== ''"
        class="wp-content"
        v-html="state.page.content.rendered" />
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import BreadcrumbsNavigation from '@/components/BreadcrumbsNavigation'

export default {
  components: {
    BreadcrumbsNavigation
  },

  setup() {
    const route  = useRoute()
    const router = useRouter()
    const state  = reactive({
      page: {}
    })

    const loadPage = async (id) => {
      await axios.get(`${process.env.VUE_APP_BACKEND}/wp-json/wp/v2/pages/${id}`)
        .then(res => {
          const page = res.data
          if (page.type === 'page' && page.status === 'publish') state.page = res.data
          else router.push({ name: '404' })
        })
        .catch(() => router.push({ name: '404' }))
    }


    onMounted(() => loadPage(route.params.id))

    return {
      state
    }
  }
}
</script>


<style lang="scss" scoped>
.page {
  position: absolute;
  top: 6%;
  left: 15%;
  width: 85%;
  height: 94%;
  overflow: auto;
  background: #fff;

  h1 {
    margin: 3rem 2rem 2rem 2rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: #4dbdbf;
  }

  p {
    color: #fff;
    font-weight: 600;
  }

  .wp-content {
    padding: 1rem 2rem 3rem 2rem;
    background: #fff;
    position: relative;
    z-index: 10;
  }
}
</style>
