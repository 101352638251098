const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}


const toggleFullscreen = (elem) => {
  if (!document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement) {
    if (elem.requestFullscreen) elem.requestFullscreen()
    else if (elem.msRequestFullscreen) elem.msRequestFullscreen()
    else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen()
    else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen(elem.ALLOW_KEYBOARD_INPUT)
  } else {
    if (document.exitFullscreen) document.exitFullscreen()
    else if (document.msExitFullscreen) document.msExitFullscreen()
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
  }
}


let counter = 0
const isTimeToUpdate = (updateRate) => counter++ % updateRate === 0


const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
const isSafari       = () => navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 ? true : false


const buildFormData = (data) => {
  /* https://stackoverflow.com/questions/16104078/appending-array-to-formdata-and-send-via-ajax */
  const formData = new FormData()
  for (let key in data) {
    Array.isArray(data[key])
      ? data[key].forEach(value => formData.append(key + '[]', value))
      : formData.append(key, data[key])
  }
  return formData
}


const findIndicesOfMax = (inp, count) => {
  const outp = []
  for (let i = 0; i < inp.length; i++) {
    outp.push(i)
    if (outp.length > count) {
      outp.sort((a, b) => inp[b] - inp[a])
      outp.pop()
    }
  }
  return outp
}


const shadeColor = (c, a) => '#' + c.replace(/^#/, '').replace(/../g, c => ('0'+Math.min(255, Math.max(0, parseInt(c, 16) + a)).toString(16)).substr(-2))

module.exports = {
  appHeight,
  toggleFullscreen,
  isTimeToUpdate,
  isMobileDevice,
  isSafari,
  buildFormData,
  findIndicesOfMax,
  shadeColor
}
