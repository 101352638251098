<template>
  <teleport to="#breadcrumbs">
    <ul class="breadcrumbs">
      <li
          v-for="(bc, idx) in breadcrumbs"
          :key="bc"
          @click="goTo(idx, bc.id)">
        <b v-if="idx != 0">0{{ idx }}.</b>
        <span v-html="bc.title" />
        <span class="arrow" v-if="idx === 1" v-html="'▼'" />
        <ul
            class="dropdown"
            v-if="idx === 1">
          <li
              v-for="page in rootPages"
              :key="page.id"
              @click.stop="goTo(idx, page.id)">
            {{ page.title }}
          </li>
        </ul>
      </li>
    </ul>
    <div class="icon" @click="toggleFullscreen()">
      <svg
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          style="enable-background:new 0 0 24 24">
        <path d="M10.091,10.591c-0.128,0-0.256-0.049-0.354-0.146L5,5.707V10H4V4.5C4,4.224,4.224,4,4.5,4H10v1H5.707 l4.737,4.737c0.098,0.098,0.146,0.226,0.146,0.354s-0.049,0.256-0.146,0.354S10.219,10.591,10.091,10.591z"/>
        <path d="M13.909,10.591c-0.128,0-0.256-0.049-0.354-0.146s-0.146-0.226-0.146-0.354s0.049-0.256,0.146-0.354 L18.293,5H14V4h5.5C19.776,4,20,4.224,20,4.5V10h-1V5.707l-4.737,4.737C14.165,10.542,14.037,10.591,13.909,10.591z"/>
        <path d="M10,20H4.5C4.224,20,4,19.776,4,19.5V14h1v4.293l4.737-4.737c0.195-0.195,0.512-0.195,0.707,0 c0.098,0.098,0.146,0.226,0.146,0.354s-0.049,0.256-0.146,0.354L5.707,19H10V20z"/>
        <path d="M19.5,20H14v-1h4.293l-4.737-4.737c-0.098-0.098-0.146-0.226-0.146-0.354s0.049-0.256,0.146-0.354 c0.195-0.195,0.512-0.195,0.707,0L19,18.293V14h1v5.5C20,19.776,19.776,20,19.5,20z"/>
      </svg>
    </div>
  </teleport>
</template>


<script>
import { ref, computed, watch } from 'vue'
import useEmitter from '@/composables/useEmitter'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  props: {
    page: Object
  },

  setup (props) {
    const store     = useStore()
    const router    = useRouter()
    const emitter   = useEmitter()
    const pages     = computed(() => store.getters.pages)
    const rootPages = computed(() => store.getters.rootPages)
    let parentId    = parseInt(JSON.stringify(props.page.parent))

    const breadcrumbs = ref([
      {
        id: props.page.id,
        title: props.page.title.rendered,
      }
    ])

    const buildBreadcrumbs = () => {
      if (props.page.id === 0) return
      if (parentId === 0) {
        breadcrumbs.value.unshift({
          id: 0,
          title: '<svg viewBox="0 0 20 19"><path d="M8,17 L8,11 L12,11 L12,17 L17,17 L17,9 L20,9 L10,0 L0,9 L3,9 L3,17 L8,17 Z" /></svg> Home',
        })
        return
      }
      const foundPage = pages.value.find((page) => page.id === parentId)
      breadcrumbs.value.unshift({
        id: foundPage.id,
        title: foundPage.title,
      })
      parentId = foundPage.parent
      buildBreadcrumbs()
    }

    const goTo = (idx, id) => {
      emitter.emit('play-btn-sound')
      if (id === 0) {
        router.push({
          name: 'Home'
        })
        return
      }
      router.push({
        name: idx === 1 ? 'Topic' : 'Page',
        params: { id }
      })
    }

    const toggleFullscreen = () => {
      emitter.emit('play-btn-sound')
      emitter.emit('toggle-fullscreen')
    }

    if (pages.value.length > 0) buildBreadcrumbs()

    watch(pages, () => buildBreadcrumbs())

    return {
      breadcrumbs,
      goTo,
      rootPages,
      toggleFullscreen
    }
  }
}
</script>


<style lang="scss" scoped>
.icon {
  margin-left: auto;
  cursor: pointer;
  margin-right: 0.5rem;
  border: 1px solid #4dbdbf;
  border-radius: 50%;
  padding: 0.2rem;

  svg {
    width: 1.25rem;
    display: block;
    fill: #4dbdbf;
  }

  &:hover {
    svg {
      fill: #fff;
    }
  }
}

ul.breadcrumbs {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;

  > li {
    padding: 0 1rem;
    position: relative;
    color: #4dbdbf;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    > b {
      font-weight: 300;
    }

    > span {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.25rem;
      font-weight: 700;
    }

    :deep(svg) {
      vertical-align: bottom;
      height: 0.8rem;
      margin-right: 0.4rem;
      fill: #4dbdbf;
      transition: all 250ms ease-in-out;
    }

    &:hover {
      color: #fff;

      :deep(svg) {
        fill: #fff;
      }
    }

    &::after {
      position: absolute;
      right: -0.45rem;
      content: '⇢';
      color: #999;
      pointer-events: none;
    }

    &:first-child {
      > span {
        margin-left: 0;
      }
    }

    &:last-child {
      color: #fff;

      &:active {
        pointer-events: none;
      }

      :deep(svg) {
        fill: #fff;
      }

      &::after {
        display: none;
      }
    }

    .arrow {
      font-size: 0.5rem;
      display: inline-block;
      margin-bottom: -0.2rem;
    }

    ul.dropdown {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 1.25rem;
      padding: 0.75rem 1rem;
      margin: 0;
      transform: translateY(1.4rem);
      list-style: none;
      background: #fff;
      border-radius: 0.5rem;
      transition: opacity 250ms ease-in-out;
      box-shadow: 0 0 1rem rgba($color: #000, $alpha: 0.1);

      li {
        white-space: nowrap;
        border-bottom: 1px dotted #ddd;
        padding: 0.1rem 0;
        font-size: 0.85rem;
        cursor: pointer;
        color: #000;

        &:last-child {
          padding-bottom: 0;
          border: none;
        }

        &:hover {
          color: #777;
        }
      }
    }

    &:hover {
      ul.dropdown {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
</style>
