<template>
  <transition
      name="fade"
      appear>
    <div class="touchpoint">
      <svg
          viewBox="0 0 320 320">
        <path class="p0" d="M160,289.64C88.4,289.64,30.36,231.6,30.36,160" />
        <path class="p0" d="M160,30.36c71.6,0,129.64,58.04,129.64,129.64" />
        <path class="p1" d="M160,3.75c86.29,0,156.25,69.96,156.25,156.25S246.29,316.25,160,316.25S3.75,246.29,3.75,160" />
        <path class="p2" d="M75.36,75.36c46.75-46.75,122.54-46.75,169.29,0s46.75,122.54,0,169.29s-122.54,46.75-169.29,0" />
        <path class="p3" d="M160,305.33c-80.26,0-145.33-65.06-145.33-145.33S79.74,14.67,160,14.67S305.33,79.74,305.33,160" />
        <text x="52%" y="55%" alignment-baseline="middle" text-anchor="middle">i</text>
      </svg>
      <span>{{ title }}</span>
    </div>
  </transition>
</template>


<script>
export default {
  name: 'TouchPoint',

  props: {
    title: String
  }
}
</script>


<style lang="scss" scoped>
@import '~@/assets/scss/colors';

.touchpoint {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  background: rgba($color: #15161b, $alpha: 1);
  width: 3rem;
  height: 3rem;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  span {
    position: absolute;
    display: block;
    white-space: nowrap;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) translateY(135%);
    transform-origin: center top;
    text-align: center;
    background: #4dbdbf;
    color: #000;
    font-weight: 600;
    padding: 0.25rem 0.75rem;
    border-radius: 0.75rem;
    transition: all 200ms ease-in-out;
  }

  svg {
    fill: none;
    stroke: #4dbdbf;
    stroke-miterlimit: 10;
    transform-origin: center;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    left: 0.25rem;
    top: 0.25rem;
    transition: stroke 200ms ease-in-out;
    z-index: 1;

    .p0 {
      stroke-width: 7;
      animation: spin 10s linear infinite;
      transform-origin: center;
    }

    .p1{
      stroke-width: 6;
      animation: spin 7s linear infinite;
      animation-direction: reverse;
      transform-origin: center;
    }

    .p2{
      stroke-width: 6;
      animation: spin 3s linear infinite;
      transform-origin: center;
    }

    .p3{
      stroke-width: 12;
      animation: spin 4s linear infinite;
      animation-direction: reverse;
      transform-origin: center;
    }

    text {
      font-size: 900%;
      fill: #4dbdbf;
      transition: fill 200ms ease-in-out;
      stroke-width: 0;
    }
  }

  &:hover {
    svg {
      stroke: #14beb4;

      text {
        fill: #14beb4;
      }
    }

    span {
      background-color: #fff;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate3d(0, 0, 1, 0);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
</style>
