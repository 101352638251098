<template>
  <header>
    <section @click="goTo(0)">
      <div class="brand">
        Mobilität
        <br><small>der</small>
        <br>Zukunft
      </div>
      <small class="byline">#automatisiert<br>#vernetzt<br>#elektrisch</small>
    </section>
    <div class="footer">
      <ul class="meta">
        <li><a href="https://www.e-mobilbw.de/ueber-uns/kontakt-anfahrt" target="_blank">Kontakt</a></li>
        <li><a href="https://www.e-mobilbw.de/impressum" target="_blank">Impressum</a></li>
        <li><a href="https://www.e-mobilbw.de/datenschutz" target="_blank">Datenschtuz</a></li>
        <li><a href="https://www.e-mobilbw.de/barrierefreiheit" target="_blank">Barrierefreiheit</a></li>
        <li><a href="https://www.e-mobilbw.de/leichte-sprache" target="_blank">Leichte Sprache</a></li>
      </ul>
      <a href="https://www.e-mobilbw.de" target="_blank">
        <img src="@/assets/img/logo-emobil.png" />
      </a>
    </div>
  </header>
</template>


<script>
import { useRouter } from 'vue-router'
import useEmitter from '@/composables/useEmitter'

export default {
  setup () {
    const router = useRouter()
    const emitter   = useEmitter()

    const goTo = (id) => {
      emitter.emit('play-btn-sound')
      if (id === 0) {
        router.push({
          name: 'Home'
        })
        return
      }
      router.push({
        name: 'Meta',
        params: { id }
      })
    }

    return {
      goTo
    }
  }
}
</script>


<style lang="scss" scoped>
header {
  position: relative;
  width: 15.00%;
  height: 94%;
  top: 6%;
  z-index: 500;
  z-index: 1;
  background: #15161b;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  overflow: auto;

  section {
    padding: 1.5rem;
    background: #1c1c24;
    //height: 100%;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    color: #fff;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    background: #4dbdbf;
    color: #15161b;

    .brand {
      font-size: 1.75rem;
      line-height: 1.1em;
      font-weight: 400;
      text-align: center;

      small {
        font-size: 1.1rem;
        line-height: 1.25em;
        margin: 0.5rem 0;
        padding: 0 1rem;
        font-weight: 400;
        display: inline-block;
        border-top: 1px solid rgba($color: #15161b, $alpha: 0.25);
        border-bottom: 1px solid rgba($color: #15161b, $alpha: 0.25);
        color: rgba($color: #15161b, $alpha: 0.6);
        transition: all 250ms ease-in-out;
      }
    }

    .byline {
      display: block;
      color: rgba($color: #15161b, $alpha: 0.6);
      text-align: center;
      margin-top: 1.25rem;
      transition: all 250ms ease-in-out;
    }

    &:hover {
      background: #fff;
    }
  }

  .footer {
    margin-top: auto;
    padding: 1.5rem;
    background: #1c1c24;
    border-radius: 0.5rem;
    color: #fff;

    h5 {
      margin: 0 0 1rem 0;
      font-size: 0.8rem;
      text-transform: uppercase;
    }

    ul.meta {
      list-style: none;
      color: #fff;
      margin: 0 0 2rem 0;
      padding: 0;
      font-size: 0.8rem;
      line-height: 1.2rem;

      li > a {
        color: #4dbdbf;
        cursor: pointer;
        transition: color ease-in-out 250ms;
        text-decoration: none;

        &:hover {
          color: #fff;
        }
      }
    }

    img {
      width: 100%;
      display: block;
    }
  }
}
</style>
